<template>
    <div>
        <a-spin :spinning="loading">
            <a-form ref="form" :model="formState" name="form" @finish="onSearch">
                <a-row>
                    <a-form-item class="ui-form__item" name="organizationId" label="影院组织">
                        <a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="getAllCinemaList">
                            <!-- <a-select-option :value="0">全部</a-select-option> -->
                            <a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item class="ui-form__item" name="id" label="所属影院">
                        <a-select placeholder="请选择" v-model:value="formState.id" style="width: 180px;">
                            <a-select-option :value="0">全部</a-select-option>
                            <a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item class="ui-form__item" name="cinemaInfo" label="搜索(编码/名称/地址)">
                        <a-input v-model:value="formState.cinemaInfo" placeholder="请输入编码/名称/地址"></a-input>
                    </a-form-item>
                </a-row>

                <a-row>
                    <a-col :span="18">
                    </a-col>
                    <a-col :span="6" style="text-align: right;">
                        <a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
                        <a-button @click="reset">重置</a-button>
                    </a-col>
                </a-row>
            </a-form>

            <div style="margin-top: 20px;">
                <a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list" :scroll="{ x: 1100 }">
                    <template #bodyCell="{ column, record, index }">
                        <template v-if="column.key === 'info'">
                            <div>
                                影院名称：{{ record.name }}
                            </div>
                            <div>
                                影院地址： {{ record.address }}
                            </div>
                        </template>
                        <template v-if="column.key === 'ticketImg'">
                            <a-image v-if="record.buyTicketImg" style="width: 100px;" :src="record.buyTicketImg"></a-image>
                            <span v-else>--</span>
                        </template>
                        <template v-if="column.key === 'linkImg'">
                            <a-image v-if="record.miniShareImg" style="width: 100px;" :src="record.miniShareImg"></a-image>
                            <span v-else>--</span>
                        </template>
                        <template v-if="column.key === 'action'">
                            <a-dropdown :trigger="['click', 'hover']">
                                <a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
                                </a-button>
                                <template #overlay>
                                    <a-menu>
                                        <div v-permission="['operation_bill_ticket_edit']" @click="onView(record)">
                                            <a-menu-item>
                                                修改海报背景图
                                            </a-menu-item>
                                        </div>
                                    </a-menu>
                                </template>
                            </a-dropdown>
                        </template>
                    </template>
                </a-table>
            </div>
        </a-spin>
        <a-modal v-model:visible="visible" title="分享海报" width="500px" @ok="onOk">
<!--            <div>影院名称：{{ title || '-' }}</div>-->
            <a-form ref="billForm" name="billForm" :model="modelRef">
                <a-form-item label="购票页海报背景图" extra="建议宽高比：5:6.7">
                    <a-upload v-model:file-list="ticketFileList" name="file" list-type="picture-card"
                              class="avatar-uploader" action="/admin/ajaxUpload.do" :before-upload="beforeUpload"
                              @change="(file) => handleChange(file, 1)">
                        <div v-if="ticketFileList.length < 1">
                            <Icon icon="PlusOutlined"></Icon>
                            <div class="ant-upload-text">上传</div>
                        </div>
                    </a-upload>
                </a-form-item>

                <a-form-item label="小程序链接分享图" extra="建议宽高比：5:4">
                    <a-upload v-model:file-list="linkFileList" name="file" list-type="picture-card"
                              class="avatar-uploader" action="/admin/ajaxUpload.do" :before-upload="beforeUpload"
                              @change="(file) => handleChange(file, 2)">
                        <div v-if="linkFileList.length < 1">
                            <Icon icon="PlusOutlined"></Icon>
                            <div class="ant-upload-text">上传</div>
                        </div>
                    </a-upload>
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { getOrganizationList, getCinemaList, updateCinema } from '@/service/modules/cinema.js';
	export default {
		components: {
			Icon
		},
		data() {
			return {
				loading: false,
				organizationList: [],
				cinemaAllList: [],
				formState: {
					// organizationId: 0,
					id: 0,
				},
				ticketFileList: [],
				linkFileList: [],
				searchData: {},
				list: [],
				columns: [{
					title: '影院国家编码',
					dataIndex: 'code',
					width: 180
				}, {
					title: '影院信息',
					key: 'info'
				}, {
				    title: '购票页海报背景图',
				    key: 'ticketImg',
				    width: 200
				}, {
				    title: '小程序链接分享图',
				    key: 'linkImg',
				    width: 200
				}, {
					title: '操作',
					key: 'action',
					width: 100,
					fixed: 'right'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				visible: false,
				modelRef: {}
			}
		},
		created() {
			this.getOrganizationList();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.id = this.searchData.id ? this.searchData.id : undefined;
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getCinemaList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.getData();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.id = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			async onView(item) {
			    this.modelRef = {
			        id: item.id,
			        buyTicketImg: item.buyTicketImg,
			        miniShareImg: item.miniShareImg
			    };
			    if (item.buyTicketImg) {
			        this.ticketFileList = [{
			            uid: 0,
			            url: item.buyTicketImg
			        }]
			    }
			    if (item.miniShareImg) {
			        this.linkFileList = [{
			            uid: 0,
			            url: item.miniShareImg
			        }]
			    }
                this.visible = true;
			},
			async onOk() {
			    this.loading = true;
				if (!this.ticketFileList.length) {
					this.modelRef.buyTicketImg = '';
				}
				if (!this.linkFileList.length) {
					this.modelRef.miniShareImg = '';
				}
			    let ret = await updateCinema(this.modelRef);
			    this.loading = false;
			    if (ret.code === 200) {
			        this.$message.success('修改成功');
			        this.visible = false;
			        this.getData();
			    }
			},
			beforeUpload(file) {
				const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
				if (!isJpgOrPng) {
					this.$message.error('上传文件格式不正确');
				}
				const isLt1M = file.size / 1024 / 1024 < 2;
				if (!isLt1M) {
					this.$message.error('上传文件太大了');
				}
				return isJpgOrPng && isLt1M;
			},
			handleChange(info, type) {
				if (info.file.status === 'done') {
					// this.fileList = info.fileList;
					if (type === 1) {
						this.modelRef.buyTicketImg = '';
					}
					if (type === 2) {
						this.modelRef.miniShareImg = '';
					}
					const $fileList = [...info.fileList];
					$fileList.map(file => {
						if (file.response) {
							if (type === 1) {
								this.modelRef.buyTicketImg = file.response.data.imgUrl;
							}
							if (type === 2) {
								this.modelRef.miniShareImg = file.response.data.imgUrl;
							}
							file.url = file.response.data.imgUrl;
						}
						return file;
					});
				}
			},
		}
	}
</script>

<style>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
